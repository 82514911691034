import gql from "graphql-tag";

export const UPDATE_PARTNER_JOURNEY = gql`
  mutation UpdatePartnerDetailsForm($input: PartnerDetailsFormInput) {
    updatePartnerDetailsForm(input: $input) {
      address
      asfNumber
      asfPdf {
        name
        url
      }
      authorized
      cae
      citizenCardPdf {
        name
        url
      }
      legalRepresentativePdf{
        name
        url
      }
      city
      adminContact {
        personEmail
        personName
        personPhone
        userId
      }
      commercialContact {
        personEmail
        personName
        personPhone
        userId
      }
      financialContact {
        userId
        personName
        personEmail
        personPhone
      }
      legalContact {
        personEmail
        personName
        personLegalId
        personPhone
        personBirthdate
        userId
      }
      consents {
        usageConsent
        accuracyConsent
        dataConsent
      }
      insurancePolicyNumber
      insurancePolicyRenewalDate
      name
      nif
      particularConditionsPdf {
        name
        url
      }
      permanentCertificateNumber
      postalCode
      partnershipType
      verified
      uuid
      additionalAttributes{
        isSameCommercial
        isSameFinancial
      }
      insurers
      otherMediatorAgreement
      volume
      worksFor
      pdeamsPdf{
        name
        url
      }
    }
  }
`;
