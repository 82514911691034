import React, { useCallback, useState, useEffect } from "react";
import RoundTick from "~images/roundGreenTick.png";
import { apiErrorHandler } from "~utils/apiErrorHandler";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Spinner } from "~components/spinner";
import { useSelector } from "react-redux";
import { GET_PARTNER_FORMS } from "../../Details/utils/queries";
import "./index.less";
import { UPDATE_PARTNER_JOURNEY } from "../utils/mutation";

const ThankYouPartnerDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [partnerData, setPartnerData] = useState(null);
  const nif = useSelector((state) => state?.userData?.nif?.split(" ").join(""));

  const [getPartnerDetails] = useLazyQuery(GET_PARTNER_FORMS, {
    fetchPolicy: "no-cache",
    onError: useCallback((error: any) => {
      apiErrorHandler(error);
    }, []),
    onCompleted: useCallback(({ getPartnerDetailsForm }: any) => {
      setPartnerData(getPartnerDetailsForm);
      if (getPartnerDetailsForm.partnershipType === "PROMOTERS") {
        updateStatusPromoters({
          variables: {
            input: {
              nif: nif?.replace(/[-\s]/g, ""),
              verified: "PENDING",
            },
          },
        });
      } else {
        setIsLoading(false);
      }
    }, []),
  });

  const [updateStatusPromoters] = useMutation(UPDATE_PARTNER_JOURNEY, {
    onError: useCallback((err: any) => {
      apiErrorHandler(err);
    }, []),
    onCompleted: useCallback((data: any) => {
      setIsLoading(false);
    }, []),
  });

  useEffect(() => {
    if (nif) {
      setTimeout(() => {
        getPartnerDetails({
          variables: {
            nif: nif?.replace(/[-\s]/g, ""),
          },
        });
      }, 3000);
    }
  }, []);

  let header, message;
  if (
    partnerData?.adminContact[0]?.personEmail ===
      partnerData?.legalContact[0]?.personEmail ||
    partnerData?.partnershipType === "INDIVIDUAL"
  ) {
    if (
      partnerData?.signatureDeliveryMethod === "URL" &&
      partnerData?.isSignatureComplete
    ) {
      header = "O pedido de acesso à MUDEY Pro foi submetido com sucesso.";
      message =
        "Iremos analisar, e confirmamos por email, no máximo até 2 dias úteis.";
    } else if (partnerData?.signatureDeliveryMethod === "EMAIL") {
      header = "Documentação enviada para assinatura por email.";
      message =
        "Assim que recebermos os documentos assinados, iremos analisar, e confirmamos por email, no máximo até 2 dias úteis.";
    }
  } else if (
    partnerData?.adminContact[0]?.personEmail !==
    partnerData?.legalContact[0]?.personEmail
  ) {
    header = "Documentação enviada para assinatura por email.";
    message =
      "Assim que recebermos os documentos assinados, iremos analisar, e confirmamos por email, no máximo até 2 dias úteis.";
  }

  if (partnerData?.partnershipType === "PROMOTERS") {
    return (
      <div className="new-thank-you">
        {isLoading && <Spinner />}
        <img src={RoundTick} className="round-tick-img" loading="lazy" />
        <h1 className="heading-text">
          Terminaste o teu registo. O que acontece agora?
        </h1>
        <p className="message-text">
          A MUDEY vai analisar o teu registo e, no máximo em 2 dias úteis, entra
          em contacto contigo.
        </p>
        <p className="message-text">
          Qualquer questão que tenhas podes sempre falar connosco através do
          chat disponível no canto inferior direito.
        </p>
        <p className="message-text">Até já!</p>
      </div>
    );
  }

  return (
    <div className="new-thank-you">
      {isLoading && <Spinner />}
      <img src={RoundTick} className="round-tick-img" loading="lazy" />
      {header && message && (
        <>
          {/* <h1 className="heading-text">{header}</h1> */}
          {/* <p className="message-text">{message}</p> */}
          <h1 className="heading-text">
            Pedido de acesso ao período experimental recebido com sucesso.
          </h1>
          <p className="message-text">
            Vamos analisar a documentação que submeteste e respondemos até 2
            dias úteis para o email que indicaste.
          </p>
          {/* <p className="message-text">
            Qualquer questão que tenhas, podes entrar em contacto com a MUDEY
            via chat ou através do email mudeypro@mudey.pt
          </p> */}
        </>
      )}
      <p className="message-text">
        Qualquer questão que tenhas, podes entrar em contacto com a MUDEY via
        chat ou através do email{" "}
        <a href="mailto:mudeypro@mudey.pt">mudeypro@mudey.pt</a>
      </p>
      <p className="message-text">Até já!</p>
      <p className="message-text">MUDEY</p>
    </div>
  );
};

export default ThankYouPartnerDetails;
