import * as Yup from "yup";
import moment from "moment";
import validator from "validator";
export const validationSchemaSectionOne = Yup.object().shape({
  iban: Yup.mixed().when("partnershipType", {
    is: (partnershipType: any) => {
      return !["PDEAMS", "PROMOTERS"].includes(partnershipType);
    },
    then: Yup.mixed()
      .test("phone", "Este campo é obrigatório", (value: any) => {
        if (value) {
          return validator.isIBAN(value);
        }
      })
      .required("Este campo é obrigatório")
      .typeError("Este campo é obrigatório"),
  }),
  // iban: Yup.mixed()
  //   .test("phone", "Este campo é obrigatório", (value: any) => {
  //     if (value) {
  //       return validator.isIBAN(value);
  //     }
  //   })
  //   .required("Este campo é obrigatório")
  //   .typeError("Este campo é obrigatório"),
  iban2: Yup.mixed()
    .test("phone", "Este campo é obrigatório", (value: any) => {
      if (value) {
        return validator.isIBAN(value);
      }
    })
    .required("Este campo é obrigatório")
    .typeError("Este campo é obrigatório"),
});
